<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{$t('editMatch')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <seeker-matches :tournaments="tournaments" :divisions="divisions" :seasons="seasons" @updateFixture="updateFixture" :edit="true" />
    <hr>
    <table-results :fixture="fixture" :edit="true" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import TableResults from '../partials/TableResults'
import SeekerMatches from '../partials/SeekerMatches'

export default {
  components: {
    TableResults,
    SeekerMatches
  },
  data () {
    return {
      tournaments: [],
      divisions: [],
      seasons: [],
      fixture: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'user',
      'console'
    ])
  },
  mounted() {
    document.title = `${this.$t('editMatch')} - ITFA`
    this.fetchData()
  },
  methods: {
    updateFixture (params) {
      this.fixture = params.fixture
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/teams/results/edit`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.tournaments = data.tournaments
        this.divisions = data.divisions
        this.seasons = data.seasons
      })
    }
  }
}
</script>
