<template>
  <b-form class="row" @submit.prevent="searchMatches()">
    <div class="form-group col-6 col-md-3">
      <label for="tournament">{{ $t('tournament') }}</label>
      <select name="tournament" id="tournament" class="form-control" @change="filterDivisions()" v-model="tournamentId">
        <option value="0">{{ $t('selectOption') }}</option>
        <option v-for="tou in tournaments" :key="`tou-${tou.id}`" :value="tou.id">{{ tou.name }}</option>
      </select>
    </div>
    <div class="form-group col-6 col-md-3">
      <label for="division">{{ $t('division') }}</label>
      <select name="division" id="division" class="form-control" @change="filterSeasons()" v-model="divisionId">
        <option value="0">{{ $t('selectOption') }}</option>
        <option v-for="division in divisionsFilter" :key="`division-${division.id}`" :value="division.id">{{ division.name }}</option>
      </select>
    </div>
    <div class="form-group col-6 col-md-3">
      <label for="season">{{ $tc('season', 2) | toCapitalize }}</label>
      <select name="season" id="season" class="form-control" v-model="seasonId">
        <option value="0">{{ $t('selectOption') }}</option>
        <option v-for="season in seasonsFilter" :key="`season-${season.id}`" :value="season.id">{{ season.name }}</option>
      </select>
    </div>
    <div class="form-group col-6 col-md-3">
      <label for="round">{{ $tc('round', 1) | toCapitalize }}</label>
      <b-form-input type="number" v-model="round" v-validate="'numeric'" />
    </div>
    <div class="col-12">
      <b-button type="submit" block variant="primary">{{ $t('search') }}</b-button>
    </div>
  </b-form>
</template>
<script>
export default {
  props: {
    edit: {
      required: false,
      type: Boolean,
      default: false
    },
    tournaments: {
      required: true,
      type: Array
    },
    divisions: {
      required: true,
      type: Array
    },
    seasons: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      round: 0,
      tournamentId: 0,
      divisionId: 0,
      divisionsFilter: [],
      seasonId: 0,
      seasonsFilter: [],
      teamId: null
    }
  },
  methods: {
    searchMatches () {
      const params = {
        tournament_id: this.tournamentId,
        division_id: this.divisionId,
        season_id: this.seasonId,
        round: this.round
      }
      let path = `auth/${this.lang}/${this.console}/teams/results/`
      if (this.edit) {
        path += 'filter-matches-for-edit'
      } else {
        path += 'filter-matches'
      }
      this.$axios.get(path, { params }).then(response => {
        const data = response.data
        this.teamId = this.edit ? null : data.team_id
        this.fixture = data.fixture.map(f => {
          if (f.local_goals === f.visit_goals) {
            f.status = 'd'
          } else {
            if (f.team_local === this.teamId) {
              if (f.local_goals > f.visit_goals) {
                f.status = 'w'
              } else if (f.local_goals < f.visit_goals) {
                f.status = 'l'
              }
            } else {
              if (f.visit_goals > f.local_goals) {
                f.status = 'w'
              } else if (f.visit_goals < f.local_goals) {
                f.status = 'l'
              }
            }
          }
          return f
        })
        this.$emit('updateFixture', {
          fixture: this.fixture,
          teamId: this.teamId
        })
      })
    },
    filterDivisions () {
      this.divisionsFilter = this.divisions.filter(div => {
        return div.tournament_id === this.tournamentId
      })
      this.divisionId = 0
      this.seasonsFilter = []
      this.seasonId = 0
    },
    filterSeasons () {
      this.seasonsFilter = this.seasons.filter(sea => {
        return sea.division_id === this.divisionId
      })
      this.seasonId = 0
    }
  }
}
</script>
