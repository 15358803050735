<template>
  <div class="w-100">
    <b-modal id="modalImages" :title="$t('addPictures')" @shown="init">
      <div class="row">
        <div class="form-group col-6" v-for="(image, i) in images" :key="`image-${i}}`">
          <form class="card w-100" @submit.prevent="save(i)" :data-vv-scope="`form-${i}`" :key="`form-${i}`">
            <img v-if="!image.link" src="../../../../assets/images/not-available.jpg" class="card-img-top" alt="No disponible">
            <img v-else :src="image.link" class="card-img-top" :alt="image.name">
            <div class="card-body">
                <b-form-input v-model="images[i].name" v-validate="{required: true, max: 100}" :name="`name${i}`" :data-vv-as="$t('name')" :placeholder="$t('name')"></b-form-input>
                <span class="error-inputs w-100">{{ errors.first(`name${i}`) }}</span>
                <b-button block variant="info" @click="change(i)">{{ $t('changeImage') }}</b-button>
                <b-button block type="submit" variant="info">{{ $t('toUpdate') }}</b-button>
                <b-button v-if="image.id" block variant="danger" @click="destroyImages(i, image.id)"><i class="fa fa-trash px-3"></i></b-button>
            </div>
          </form>
        </div>
      </div>
      <b-button v-if="showButton" block variant="info" @click="addImage()">
        <span class="mr-3">Agregar</span><i class="fa fa-plus"></i>
      </b-button>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalImages')">{{ $t('close') }}</b-button>
      </template>
    </b-modal>
    <input type="file" name="file" ref="file" class="hide" @change="previewImage($event)" style="display:none;" accept=".png,.jpg,.jpeg">
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fixtureId: {
      required: true,
      type: Number
    },
    images: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      showButton: true,
      file: null,
      currentIndex: null
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    previewImage (e) {
      const input = e.target  
      this.images[this.currentIndex].file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.images[_self.currentIndex].link = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.images[this.currentIndex].file = null
      }
    },
    change (index) {
      this.currentIndex = index
      this.$refs.file.click()
    },
    deleteImage (index) {
      this.images.splice(index, 1)
    },
    addImage () {
      if (this.images.length >= 4) {
        return
      }
      this.images.push({
        name: '',
        link: null
      })
      if (this.images.length >= 4) {
        this.showButton = false
      }
    },
    init () {
      if (this.images.length >= 5) {
        this.showButton = false
      } else {
        this.showButton = true
      }
    },
    save (index) {
      this.$validator.validateAll(`form-${index}`).then(result => {
        if (result) {
          const image = this.images[index]
          const payload = new FormData()
          payload.append('name', image.name)
          if (image.file) {
            payload.append('file', image.file)
          }
          payload.append('order', index + 1)
          if (image.id) {
            payload.append('id', image.id)
          }
          const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/images/store`
          this.$axios.post(path, payload).then(response => {
            this.$toastr.success(response.data.message)
            this.images[index].id = response.data.id
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    destroyImages (index, imageId) {
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/images/${imageId}/delete`
      this.$axios.delete(path).then(response => {
        this.$toastr.success(response.data.message)
        this.images.splice(index, 1)
      })
    }
  }
}
</script>
