var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('b-table',{attrs:{"striped":"","bordered":"","hover":"","items":_vm.fixture,"fields":_vm.columns},scopedSlots:_vm._u([{key:"local",fn:function(row){return _c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(row.item.team_local_name))]),_c('img',{staticClass:"img-icon ml-3",attrs:{"src":row.item.team_local_image,"alt":row.item.team_local_name}})])}},{key:"result",fn:function(row){return _c('div',{staticClass:"text-center"},[(
					row.item.played || (row.item.check_local || row.item.check_visit)
				)?_c('h5',[_vm._v("\n\t\t\t\t"+_vm._s(row.item.local_goals)+" - "+_vm._s(row.item.visit_goals)+"\n\t\t\t")]):_vm._e(),_c('span',[_vm._v(_vm._s(row.item.date_game))])])}},{key:"visitor",fn:function(row){return _c('div',{},[_c('img',{staticClass:"img-icon mr-3",attrs:{"src":row.item.team_visit_image,"alt":row.item.team_visit_name}}),_c('span',[_vm._v(_vm._s(row.item.team_visit_name))])])}},{key:"icons",fn:function(row){return _c('div',{staticClass:"text-center"},[(row.item.check_local || row.item.check_visit)?_c('span',[(row.item.status === 'w')?_c('i',{staticClass:"fa fa-thumbs-up text-info fa-2x"}):_vm._e(),(row.item.status === 'l')?_c('i',{staticClass:"fa fa-thumbs-down text-danger fa-2x"}):_vm._e(),(row.item.status === 'd')?_c('i',{staticClass:"fa fa-circle fa-2x"}):_vm._e()]):_vm._e()])}},{key:"actions",fn:function(row){return [_c('div',{staticClass:"w-100 text-center"},[(_vm.edit)?_c('b-button',{attrs:{"to":{
						name: 'TeamBackResultsEditMatch',
						params: { lang: _vm.lang, console: _vm.console, fixtureId: row.item.id },
					}}},[_c('i',{staticClass:"fa fa-refresh px-1"})]):_c('b-dropdown',{attrs:{"id":"ddTeamOptions","right":"","noCaret":""}},[_c('div',{staticClass:"px-2",attrs:{"slot":"button-content"},slot:"button-content"},[_c('i',{staticClass:"fa fa-ellipsis-v"})]),_c('b-dropdown-item',{attrs:{"to":{
							name: 'DivisionMatchDetail',
							params: {
								lang: _vm.lang,
								console: _vm.console,
								divisionSlug: row.item.division_slug,
								fixtureId: row.item.id,
							},
						}}},[_vm._v(_vm._s(_vm.$t('watchMatch')))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalVideos(row.item.id)}}},[_vm._v(_vm._s(_vm.$t('editVideos')))]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.openModalImages(row.item.id)}}},[_vm._v(_vm._s(_vm.$t('addPictures')))]),_c('b-dropdown-item',{attrs:{"to":{
							name: 'TeamBackResultsEdit',
							params: {
								lang: _vm.lang,
								console: _vm.console,
								fixtureId: row.item.id,
								teamId: _vm.teamId,
							},
						}}},[_vm._v(_vm._s(_vm.$t('edit')))])],1)],1)]}}])}),_c('modal-videos',{attrs:{"fixture-id":_vm.fixtureId,"videos":_vm.videos}}),_c('modal-images',{attrs:{"fixture-id":_vm.fixtureId,"images":_vm.images}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }