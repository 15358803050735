<template>
	<div class="w-100">
		<b-table striped bordered hover :items="fixture" :fields="columns">
			<div slot="local" slot-scope="row" class="text-right">
				<span>{{ row.item.team_local_name }}</span>
				<img
					:src="row.item.team_local_image"
					:alt="row.item.team_local_name"
					class="img-icon ml-3"
				/>
			</div>
			<div slot="result" slot-scope="row" class="text-center">
				<h5
					v-if="
						row.item.played || (row.item.check_local || row.item.check_visit)
					"
				>
					{{ row.item.local_goals }} - {{ row.item.visit_goals }}
				</h5>
				<span>{{ row.item.date_game }}</span>
			</div>
			<div slot="visitor" slot-scope="row">
				<img
					:src="row.item.team_visit_image"
					:alt="row.item.team_visit_name"
					class="img-icon mr-3"
				/>
				<span>{{ row.item.team_visit_name }}</span>
			</div>
			<div slot="icons" slot-scope="row" class="text-center">
				<span v-if="row.item.check_local || row.item.check_visit">
					<i
						v-if="row.item.status === 'w'"
						class="fa fa-thumbs-up text-info fa-2x"
					></i>
					<i
						v-if="row.item.status === 'l'"
						class="fa fa-thumbs-down text-danger fa-2x"
					></i>
					<i v-if="row.item.status === 'd'" class="fa fa-circle fa-2x"></i>
				</span>
			</div>
			<template slot="actions" slot-scope="row">
				<div class="w-100 text-center">
					<b-button
						:to="{
							name: 'TeamBackResultsEditMatch',
							params: { lang: lang, console: console, fixtureId: row.item.id },
						}"
						v-if="edit"
					>
						<i class="fa fa-refresh px-1"></i>
					</b-button>
					<b-dropdown v-else id="ddTeamOptions" right noCaret>
						<div slot="button-content" class="px-2">
							<i class="fa fa-ellipsis-v"></i>
						</div>
						<b-dropdown-item
							:to="{
								name: 'DivisionMatchDetail',
								params: {
									lang: lang,
									console: console,
									divisionSlug: row.item.division_slug,
									fixtureId: row.item.id,
								},
							}"
							>{{ $t('watchMatch') }}</b-dropdown-item
						>
						<b-dropdown-item @click="openModalVideos(row.item.id)">{{
							$t('editVideos')
						}}</b-dropdown-item>
						<b-dropdown-item @click="openModalImages(row.item.id)">{{
							$t('addPictures')
						}}</b-dropdown-item>
						<b-dropdown-item
							:to="{
								name: 'TeamBackResultsEdit',
								params: {
									lang: lang,
									console: console,
									fixtureId: row.item.id,
									teamId: teamId,
								},
							}"
							>{{ $t('edit') }}</b-dropdown-item
						>
					</b-dropdown>
				</div>
			</template>
		</b-table>
		<modal-videos :fixture-id="fixtureId" :videos="videos" />
		<modal-images :fixture-id="fixtureId" :images="images" />
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalVideos from '../Results/ModalVideos'
import ModalImages from '../Results/ModalImages'

export default {
	components: {
		ModalVideos,
		ModalImages,
	},
	props: {
		edit: {
			required: false,
			type: Boolean,
			default: false,
		},
		fixture: {
			required: true,
			type: Array,
		},
		teamId: {
			required: false,
			type: Number,
		},
	},
	data() {
		return {
			fixtureId: 0,
			videos: [],
			images: [],
			columns: [
				{ key: 'round', label: this.$tc('round', 1), sortable: false },
				{ key: 'local', label: 'Local', sortable: false },
				{ key: 'result', label: '', sortable: false },
				{ key: 'visitor', label: this.$t('visitor'), sortable: false },
				{ key: 'icons', label: '', sortable: false },
				{ key: 'actions', label: '' },
			],
		}
	},
	computed: {
		...mapGetters(['lang', 'user', 'console']),
	},
	methods: {
		openModalImages(fixtureId) {
			this.fixtureId = fixtureId
			const path = `auth/${this.lang}/${
				this.console
			}/teams/results/${fixtureId}/images/show`
			this.$axios.get(path).then((response) => {
				this.images = response.data.data
				this.$root.$emit('bv::show::modal', 'modalImages')
			})
		},
		openModalVideos(fixtureId) {
			this.fixtureId = fixtureId
			const path = `auth/${this.lang}/${
				this.console
			}/teams/results/${fixtureId}/videos/show`
			this.$axios.get(path).then((response) => {
				this.videos = response.data.data
				this.$root.$emit('bv::show::modal', 'modalVideos')
			})
		},
	},
}
</script>
