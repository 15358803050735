<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalVideos" :title="$t('editVideos')" @shown="init">
      <div class="row" v-for="(video, i) in videos" :key="`video-${i}}`">
        <div class="form-group col-12">
          <label :for="`name-${i}`">{{ $t('name') }}</label>
          <b-form-input v-model="videos[i].name" v-validate="{required: true, max: 100}" :name="`name${i}`" :data-vv-as="$t('name')"></b-form-input>
          <span class="error-inputs w-100">{{ errors.first(`name${i}`) }}</span>
        </div>
        <div class="form-group col-12">
          <label :for="`code-${i}`">{{ $t('videoCode') }}</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" :id="`spancode-${i}`">www.youtube.com/watch?v=</span>
            </div>
            <input type="text" class="form-control" :id="`code-${i}`" v-model="videos[i].code" v-validate="{required: true}" :name="`code${i}`" :data-vv-as="$t('videoCode')">
          </div>
          <span class="error-inputs w-100">{{ errors.first(`code${i}`) }}</span>
        </div>
        <div class="col-12 mb-3">
          <b-button block variant="danger" @click="deleteVideo(i)"><i class="fa fa-trash px-3"></i></b-button>
        </div>
      </div>
      <b-button v-if="showButton" block variant="info" @click="addVideo()">
        <span class="mr-3">Agregar</span><i class="fa fa-plus"></i>
      </b-button>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalVideos')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fixtureId: {
      required: true,
      type: Number
    },
    videos: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      showButton: true
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    deleteVideo (index) {
      this.videos.splice(index, 1)
    },
    addVideo () {
      if (this.videos.length >= 5) {
        return
      }
      this.videos.push({
        name: '',
        code: ''
      })
      if (this.videos.length >= 5) {
        this.showButton = false
      }
    },
    init () {
      if (this.videos.length >= 5) {
        this.showButton = false
      } else {
        this.showButton = true
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            videos: this.videos
          }
          const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/videos/store`
          this.$axios.post(path, payload).then(response => {
            this.$toastr.success(response.data.message)
            this.$root.$emit('bv::hide::modal', 'modalVideos')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    }
  }
}
</script>
